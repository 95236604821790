import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const PrivacyPolicy = ({ toggle, modal }) => {
    return (
        <>
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>Privacy Policy</ModalHeader>
                <ModalBody>
                    <div>
                        <p>
                            Kwebbels Kinderboeken, Postbus 96, 5140AB&nbsp;
                            Waalwijk, is verantwoordelijk voor de verwerking van
                            persoonsgegevens zoals weergegeven in deze
                            privacyverklaring.
                        </p>
                        <p>Contactgegevens:</p>
                        <p>
                            www.kwebbelskinderboeken.nl
                            <br />
                            Postbus 96
                            <br />
                            5140AB&nbsp; Waalijk
                            <br /> +31 76 5300867
                        </p>
                        <p>
                            Ruud van Dal is de Functionaris Gegevensbescherming
                            van Kwebbels Kinderboeken Hij/zij is te bereiken via
                            r.vandal@dpsolutions.nl
                        </p>
                        <p>Persoonsgegevens die wij verwerken</p>
                        <p>
                            Kwebbels Kinderboeken verwerkt uw persoonsgegevens
                            doordat u gebruik maakt van onze diensten en/of
                            omdat u deze zelf aan ons verstrekt. Hieronder vindt
                            u een overzicht van de persoonsgegevens die wij
                            verwerken:
                        </p>
                        <ul>
                            <li>Voor- en achternaam</li>
                            <li>Geslacht</li>
                            <li>Adresgegevens</li>
                            <li>Telefoonnummer</li>
                            <li>E-mailadres</li>
                            <li>
                                Overige persoonsgegevens die u actief verstrekt
                                bijvoorbeeld door een profiel op deze website
                                aan te maken, in correspondentie en telefonisch
                            </li>
                            <li>
                                Lijst met contactgegevens van de klant via een
                                app
                            </li>
                            <li>Bankrekeningnummer</li>
                        </ul>
                        <p>
                            Bijzondere en/of gevoelige persoonsgegevens die wij
                            verwerken
                        </p>
                        <p>
                            Onze website en/of dienst heeft niet de intentie
                            gegevens te verzamelen over websitebezoekers die
                            jonger zijn dan 16 jaar. Tenzij ze toestemming
                            hebben van ouders of voogd. We kunnen echter niet
                            controleren of een bezoeker ouder dan 16 is. Wij
                            raden ouders dan ook aan betrokken te zijn bij de
                            online activiteiten van hun kinderen, om zo te
                            voorkomen dat er gegevens over kinderen verzameld
                            worden zonder ouderlijke toestemming. Als u er van
                            overtuigd bent dat wij zonder die toestemming
                            persoonlijke gegevens hebben verzameld over een
                            minderjarige, neem dan contact met ons op via
                            info@kwebbelskinderboeken.nl, dan verwijderen wij
                            deze informatie.
                        </p>
                        <p>
                            Met welk doel en op basis van welke grondslag wij
                            persoonsgegevens verwerken
                        </p>
                        <p>
                            Kwebbels Kinderboeken verwerkt uw persoonsgegevens
                            voor de volgende doelen:
                        </p>
                        <ul>
                            <li>Het afhandelen van uw betaling</li>
                            <li>
                                Verzenden van onze nieuwsbrief en/of
                                reclamefolder
                            </li>
                            <li>
                                U te kunnen bellen of e-mailen indien dit nodig
                                is om onze dienstverlening uit te kunnen voeren
                            </li>
                            <li>
                                U te informeren over wijzigingen van onze
                                diensten en producten
                            </li>
                            <li>
                                U de mogelijkheid te bieden een account aan te
                                maken
                            </li>
                            <li>Om goederen en diensten bij u af te leveren</li>
                        </ul>
                        <p>Geautomatiseerde besluitvorming</p>
                        <p>
                            Kwebbels Kinderboeken neemt [wel / niet] op basis
                            van geautomatiseerde verwerkingen besluiten over
                            zaken die (aanzienlijke) gevolgen kunnen hebben voor
                            personen. Het gaat hier om besluiten die worden
                            genomen door computerprogramma’s of -systemen,
                            zonder dat daar een mens (bijvoorbeeld een
                            medewerker van Kwebbels Kinderboeken) tussen zit.
                            Kwebbels Kinderboeken gebruikt de volgende
                            computerprogramma’s of -systemen:
                        </p>

                        <p>
                            [aanvullen met naam van het systeem, waarom het
                            gebruikt wordt, onderliggende logica, belang en
                            verwachte gevolgen voor betrokkene]
                        </p>

                        <p>Hoe lang we persoonsgegevens bewaren</p>

                        <p>
                            Kwebbels Kinderboeken bewaart uw persoonsgegevens
                            niet langer dan strikt nodig is om de doelen te
                            realiseren waarvoor uw gegevens worden verzameld.
                            Wij hanteren de volgende bewaartermijnen voor de
                            volgende (categorieën) van persoonsgegevens:
                        </p>

                        <p>Persoonsgegevens: Bewaartermijn is 3 jaar</p>

                        <p>Delen van persoonsgegevens met derden</p>

                        <p>
                            Kwebbels Kinderboeken verkoopt uw gegevens niet aan
                            derden en verstrekt deze uitsluitend indien dit
                            nodig is voor de uitvoering van onze overeenkomst
                            met u of om te voldoen aan een wettelijke
                            verplichting. Met bedrijven die uw gegevens
                            verwerken in onze opdracht, sluiten wij een
                            bewerkersovereenkomst om te zorgen voor eenzelfde
                            niveau van beveiliging en vertrouwelijkheid van uw
                            gegevens. Kwebbels Kinderboeken blijft
                            verantwoordelijk voor deze verwerkingen.
                        </p>

                        <p>
                            Cookies, of vergelijkbare technieken, die wij
                            gebruiken
                        </p>
                        <p>
                            Kwebbels Kinderboeken gebruikt alleen technische en
                            functionele cookies. En analytische cookies die geen
                            inbreuk maken op uw privacy. Een cookie is een klein
                            tekstbestand dat bij het eerste bezoek aan deze
                            website wordt opgeslagen op uw computer, tablet of
                            smartphone. De cookies die wij gebruiken zijn
                            noodzakelijk voor de technische werking van de
                            website en uw gebruiksgemak. Ze zorgen ervoor dat de
                            website naar behoren werkt en onthouden bijvoorbeeld
                            uw voorkeursinstellingen. Ook kunnen wij hiermee
                            onze website optimaliseren. U kunt zich afmelden
                            voor cookies door uw internetbrowser zo in te
                            stellen dat deze geen cookies meer opslaat.
                            Daarnaast kunt u ook alle informatie die eerder is
                            opgeslagen via de instellingen van uw browser
                            verwijderen.
                        </p>

                        <p>Gegevens inzien, aanpassen of verwijderen</p>
                        <p>
                            U heeft het recht om uw persoonsgegevens in te zien,
                            te corrigeren of te verwijderen. Daarnaast heeft u
                            het recht om uw eventuele toestemming voor de
                            gegevensverwerking in te trekken of bezwaar te maken
                            tegen de verwerking van uw persoonsgegevens door
                            Kwebbels Kinderboeken en heeft u het recht op
                            gegevensoverdraagbaarheid. Dat betekent dat u bij
                            ons een verzoek kunt indienen om de persoonsgegevens
                            die wij van u beschikken in een computerbestand naar
                            u of een ander, door u genoemde organisatie, te
                            sturen.
                        </p>

                        <p>
                            U kunt een verzoek tot inzage, correctie,
                            verwijdering, gegevensoverdraging van uw
                            persoonsgegevens of verzoek tot intrekking van uw
                            toestemming of bezwaar op de verwerking van uw
                            persoonsgegevens sturen naar
                            info@kwebbelskinderboeken.nl.
                        </p>

                        <p>
                            Om er zeker van te zijn dat het verzoek tot inzage
                            door u is gedaan, vragen wij u een kopie van uw
                            identiteitsbewijs met het verzoek mee te sturen.
                            Maak in deze kopie uw pasfoto, MRZ (machine readable
                            zone, de strook met nummers onderaan het paspoort),
                            paspoortnummer en Burgerservicenummer (BSN) zwart.
                            Dit ter bescherming van uw privacy. We reageren zo
                            snel mogelijk, maar binnen vier weken, op uw
                            verzoek.
                        </p>

                        <p>
                            Kwebbels Kinderboeken wil u er tevens op wijzen dat
                            u de mogelijkheid heeft om een klacht in te dienen
                            bij de nationale toezichthouder, de Autoriteit
                            Persoonsgegevens. Dat kan via de volgende link:
                            https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                        </p>

                        <p>Hoe wij persoonsgegevens beveiligen</p>
                        <p>
                            Kwebbels Kinderboeken neemt de bescherming van uw
                            gegevens serieus en neemt passende maatregelen om
                            misbruik, verlies, onbevoegde toegang, ongewenste
                            openbaarmaking en ongeoorloofde wijziging tegen te
                            gaan. Als u de indruk heeft dat uw gegevens niet
                            goed beveiligd zijn of er aanwijzingen zijn van
                            misbruik, neem dan contact op met onze
                            klantenservice of via info@kwebbelskinderboeken.nl
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Done
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default PrivacyPolicy;
