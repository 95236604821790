import { RepositoryFactory } from "../../Repository/RepositoryFactory";

let Leads = RepositoryFactory.get("leads");

export const PostLeads =
    (obj, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch(Loader(true));
        try {
            const { data } = await Leads.getLeads(obj);
            if (data) {
                console.log(data?.profile.id);
                onSuccess();
                dispatch({
                    type: "SUCCESS",
                    payload: data?.profile?.id,
                });
                dispatch(Loader(false));
            }
        } catch (error) {
            dispatch(Loader(false));
            console.log(error.message);
        }
    };
export const Loader = (val) => async (dispatch) => {
    dispatch({
        type: "LOADER",
        payload: val,
    });
};
