import React from "react";
import { Container, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";

const Footer = () => {
    const location = useLocation();

    const [PrivacyModal, setPrivacyModal] = useState(false);
    const [terms, setTerms] = useState(false);

    const privacyToggle = () => setPrivacyModal(!PrivacyModal);
    const termsToggle = () => setTerms(!terms);

    return (
        <div
            className={`footer py-3 ${
                location.pathname == "/bedankt" ? "mt-4" : ""
            }`}
        >
            <Container>
                <Row className="justify-content-center align-items-center">
                    <p className="mb-0">
                        <span
                            className="fs--14 cursor-pointer"
                            onClick={termsToggle}
                        >
                            Algemene voorwaarden
                        </span>{" "}
                        |{" "}
                        <span
                            className="fs--14 cursor-pointer"
                            onClick={privacyToggle}
                        >
                            Privacy policy
                        </span>
                    </p>
                </Row>
                <PrivacyPolicy toggle={privacyToggle} modal={PrivacyModal} />
                <TermsAndConditions toggle={termsToggle} modal={terms} />
            </Container>
        </div>
    );
};

export default Footer;
