import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
const TermsAndConditions = ({ toggle, modal }) => {
    return (
        <>
            <Modal isOpen={modal} toggle={toggle} fullscreen>
                <ModalHeader toggle={toggle}>Terms&Conditions</ModalHeader>
                <ModalBody>
                    <div>
                        Artikel 1: De identiteit van de ondernemer
                        <br />
                        Artikel 2: Levering
                        <br />
                        Artikel 3: Prijs
                        <br />
                        Artikel 4: Zichttermijn / herroepingsrecht
                        <br />
                        Artikel 5: Gegevensbeheer
                        <br />
                        Artikel 6: Garantie
                        <br />
                        Artikel 7: Aanbiedingen
                        <br />
                        Artikel 8: Overeenkomst
                        <br />
                        Artikel 9: Overmacht
                        <br />
                        Artikel 10: Eigendomsvoorbehoud<p></p>
                        <p>DE IDENTITEIT VAN DE ONDERNEMER</p>
                        <p>1.1 De identiteit van de ondernemer</p>
                        <p>
                            Clear Media b.v. handelend onder de naam: Kwebbels
                            Kinderboeken
                            <br />
                            Postbus 96
                            <br />
                            5140AB&nbsp; Waalwijk
                            <br />
                            Nederland
                            <br />
                            <br />
                            Telefoonnummer: 076 – 530 08 67
                            <br />
                            Emailadres: via dit formulier
                            <br />
                            Bereikbaarheid: Dinsdag en donderdag van 09:30 tot
                            en met 15:00.
                            <br />
                            <br />
                            KvK-nummer: 20124032
                            <br />
                            BTW-identificatienummer: NL.8161.53.875.B01
                        </p>
                        <p>
                            Voor vragen kunt terecht bij onze klantenservice op
                            dinsdag tot en met donderdag van 09:30 tot en met
                            12:30.
                        </p>
                        <p>
                            1.2 Deze algemene voorwaarden zijn van toepassing op
                            alle aanbiedingen van Kwebbels Kinderboeken. De
                            voorwaarden zijn voor een ieder toegankelijk en
                            opgenomen op de internetsite van Kwebbels
                            Kinderboeken. Op verzoek zenden wij u een
                            schriftelijk exemplaar.
                        </p>
                        <p>
                            1.3 Door het plaatsen van een bestelling geeft u te
                            kennen dat u met de leverings- en
                            betalingsvoorwaarden akkoord gaat. Kwebbels
                            Kinderboeken behoud zich het recht voor haar
                            leverings- en/of betalingsvoorwaarden na het
                            verstrijken van de looptijd te wijzigen.
                        </p>
                        <p>
                            1.4 Tenzij schriftelijk anders is overeengekomen
                            worden de algemene of specifieke voorwaarden of
                            bedingen van derden niet door Kwebbels Kinderboeken
                            erkend.
                        </p>
                        <p>
                            1.5 Kwebbels Kinderboeken garandeert dat het
                            geleverde product beantwoord aan de overeenkomst en
                            voldoet aan de in het aanbod vermelde specificaties.
                        </p>
                        <p>LEVERING</p>
                        <p>
                            2.1 Levering vindt plaats zolang de voorraad strekt.
                        </p>
                        <p>
                            2.2 In het kader van de regels van de koop op
                            afstand zal Kwebbels Kinderboeken bestellingen
                            tenminste binnen 30 dagen uitvoeren. Indien dit niet
                            mogelijk is (doordat het bestelde niet op voorraad
                            is of niet meer leverbaar), of er is om andere
                            redenen vertraging, of een bestelling kan niet
                            danwel slechts gedeeltelijk worden uitgevoerd, dan
                            ontvangt de consument binnen 1 maand na plaatsing
                            van de bestelling bericht en heeft hij in dat geval
                            het recht de bestelling zonder kosten en
                            ingebrekestelling te annuleren.
                        </p>
                        <p>
                            2.3 Aan de leveringsplicht van Kwebbels Kinderboeken
                            zal, behoudens tegenbewijs, zijn voldaan zodra de
                            door Kwebbels Kinderboeken geleverde zaken een keer
                            aan de afnemer zijn aangeboden. Bij bezorging aan
                            huis strekt het rapport van de vervoerder,
                            inhoudende de weigering van acceptatie, tot volledig
                            bewijs van het aanbod tot levering.
                        </p>
                        <p>
                            2.4 Alle op de internetsite genoemde termijnen zijn
                            indicatief. Aan de genoemde termijnen kunnen
                            derhalve geen rechten worden ontleend.
                        </p>
                        <p>PRIJS</p>
                        <p>
                            3.1 Prijzen worden binnen de looptijd van de
                            aanbieding niet verhoogd, tenzij wettelijke
                            maatregelen dit noodzakelijk maken of indien de
                            fabrikant tussentijdse prijsverhogingen doorvoert.
                        </p>
                        <p>
                            3.2 Alle prijzen op de site zijn onder voorbehoud
                            van druk- en zetfouten. Voor de gevolgen van druk-
                            en zetfouten wordt geen aansprakelijkheid aanvaard.
                        </p>
                        <p>
                            3.3 Alle prijzen op de site zijn in EURO’s en
                            inclusief de geldende BTW.
                        </p>
                        <p>ZICHTTERMIJN / HERROEPINGSRECHT</p>
                        <p>
                            4.1 Indien er sprake is van een consumentenkoop,
                            overeenkomstig de Wet verkopen op Afstand (artikel
                            7:5 BW), heeft de afnemer het recht (een deel van)
                            de geleverde goederen binnen een periode van 14
                            werkdagen zonder opgave van reden te retourneren.
                            Deze termijn begint op het moment dat de bestelde
                            zaken zijn afgeleverd. Indien de afnemer na afloop
                            van deze termijn de geleverde zaken niet aan
                            Kwebbels Kinderboeken heeft teruggezonden, is de
                            koop een feit. De afnemer is gehouden, alvorens over
                            te gaan tot terugzending, daarvan binnen de termijn
                            van 7 werkdagen na aflevering schriftelijk melding
                            te maken bij Kwebbels Kinderboeken. De afnemer dient
                            te bewijzen dat de geleverde zaken tijdig zijn
                            teruggestuurd, bijvoorbeeld door middel van een
                            bewijs van postbezorging. Terugzending van de zaken
                            dient te geschieden in de originele verpakking
                            (inclusief accessoires en bijbehorende documentatie)
                            en in nieuwstaat verkerend. Indien de zaken bij de
                            afnemer zijn gebruikt, bezwaard of op enige wijze
                            beschadigd zijn geraakt, vervalt het recht op
                            ontbinding in de zin van dit lid. Met inachtneming
                            van hetgeen is bepaald in de vorige zin, draagt
                            Kwebbels Kinderboeken zorg voor dat binnen 30 dagen
                            na goede ontvangst van de retourzending, het
                            volledige aankoopbedrag inclusief de berekende
                            verzendkosten aan de afnemer wordt terugbetaald. Het
                            terugzenden van de geleverde zaken komt geheel voor
                            rekening en risico van de afnemer.
                        </p>
                        <p>4.3 Het herroepingsrecht geldt niet voor:</p>
                        <ul>
                            <li>
                                diensten waarvan de uitvoering, met instemming
                                van de consument, is begonnen voor de termijn
                                van zeven werkdagen
                            </li>
                            <li>
                                goederen of diensten waarvan de prijs gebonden
                                is aan schommelingen op de financiële markt,
                                waarop de leverancier geen invloed heeft
                            </li>
                            <li>
                                goederen die volgens specificaties van de
                                consument zijn vervaardigd, bijvoorbeeld
                                maatwerk, of die een duidelijk persoonlijk
                                karakter hebben
                            </li>
                            <li>
                                voor goederen of diensten die door hun aard niet
                                kunnen worden teruggezonden, bijvoorbeeld i.v.m.
                                hygiëne of die snel kunnen bederven of
                                verouderen
                            </li>
                            <li>
                                audio- en video-opnamen en computerprogrammatuur
                                waarvan de consument de verzegeling heeft
                                verbroken
                            </li>
                            <li>
                                de levering van kranten en tijdschriften; voor
                                de diensten van weddenschappen en loterijen
                            </li>
                        </ul>
                        <p>GEGEVENSBEHEER</p>
                        <p>
                            5.1 Indien u een bestelling plaatst bij Kwebbels
                            Kinderboeken, dan worden uw gegevens opgenomen in
                            het klantenbestand van Kwebbels Kinderboeken.
                            Kwebbels Kinderboeken houdt zich aan de Wet
                            Persoonsregistraties en zal uw gegevens niet
                            verstrekken aan derden. Zie onze Privacy Policy.
                        </p>
                        <p>
                            5.2 Kwebbels Kinderboeken respecteert de privacy van
                            de gebruikers van de internetsite en draagt zorg
                            voor een vertrouwelijke behandeling van uw
                            persoonlijke gegevens.
                        </p>
                        <p>
                            5.3 Kwebbels Kinderboeken maakt gebruik van een
                            mailinglijst. Elke mailing bevat instructies om
                            uzelf van deze lijst te verwijderen.
                        </p>
                        <p>GARANTIE</p>
                        <p>
                            6.1 Kwebbels Kinderboeken garandeert dat de door
                            haar geleverde producten voldoen aan de eisen van
                            bruikbaarheid, betrouwbaarheid en levensduur zoals
                            deze door partijen bij de koopovereenkomst in
                            redelijkheid zijn bedoeld, en staat daarmede in voor
                            de fabrieksgarantie van het aan u geleverde product.
                        </p>
                        <p>
                            6.2 De garantietermijn van Kwebbels Kinderboeken
                            komt overeen met de fabrieksgarantietermijn.
                            Kwebbels Kinderboeken is echter nimmer
                            verantwoordelijk voor de uiteindelijke geschiktheid
                            van de zaken voor elke individuele toepassing door
                            de afnemer, noch voor eventuele adviezen t.a.v. het
                            gebruik of de toepassing van de zaken.
                        </p>
                        <p>
                            6.3 De afnemer is verplicht de geleverde zaken bij
                            ontvangst onmiddellijk te controleren. Indien blijkt
                            dat de afgeleverde zaak verkeerd, ondeugdelijk of
                            incompleet is, dan dient de afnemer (alvorens over
                            te gaan tot terugzending aan Kwebbels Kinderboeken)
                            deze gebreken onmiddelijk schriftelijk te melden aan
                            Kwebbels Kinderboeken. Eventuele gebreken of
                            verkeerd geleverde goederen dienen en kunnen
                            uiterlijk tot maximaal 2 maanden na levering aan
                            Kwebbels Kinderboeken schriftelijk te worden gemeld.
                            Terugzending van de zaken dient te geschieden in de
                            originele verpakking (inclusief accessoires en
                            bijbehorende documentatie) en in nieuwstaat
                            verkerend. Ingebruikneming na constatering van
                            gebreke, beschadiging ontstaan na constatering van
                            gebreke, bezwaring en/of doorverkoop na constatering
                            van gebreke, doet dit recht tot reclameren en
                            terugzending geheel vervallen.
                        </p>
                        <p>
                            6.4 Indien klachten van de afnemer door Kwebbels
                            Kinderboeken gegrond worden bevonden, zal Kwebbels
                            Kinderboeken naar haar keuze of de geleverde zaken
                            kosteloos vervangen of met de afnemer een
                            schriftelijke regeling over de schadevergoeding
                            treffen, met dien verstande dat de aansprakelijkheid
                            van Kwebbels Kinderboeken en mitsdien het bedrag der
                            schadevergoeding steeds beperkt is tot ten hoogste
                            het factuurbedrag der betreffende zaken, danwel
                            (naar keuze van Kwebbels Kinderboeken) tot het
                            maximale in het desbetreffende geval door de
                            aansprakelijkheidsverzekering van Kwebbels
                            Kinderboeken gedekte bedrag. Iedere
                            aansprakelijkheid van Kwebbels Kinderboeken voor
                            enige andere vorm van schade is uitgesloten,
                            waaronder mede begrepen aanvullende schadevergoeding
                            in welke vorm dan ook, vergoeding van indirecte
                            schade of gevolgschade of schade wegens gederfde
                            winst.
                        </p>
                        <p>
                            6.5 Kwebbels Kinderboeken is niet aansprakelijk voor
                            schade veroorzaakt door opzet of daarmee gelijk te
                            stellen bewuste roekeloosheid van niet-leidinggevend
                            personeel.
                        </p>
                        <p>
                            6.6 Deze garantie geldt niet indien: A) en zolang de
                            afnemer jegens Kwebbels Kinderboeken in gebreke is;
                            B) de afnemer de geleverde zaken zelf heeft
                            gepareerd en/of bewerkt of door derden heeft laten
                            repareren/of bewerken. C) de geleverde zaken aan
                            abnormale omstandigheden zijn blootgesteld of
                            anderszins onzorgvuldig worden behandeld of in
                            strijd met de aanwijzingen van Kwebbels Kinderboeken
                            en/of gebruiksaanwijzing op de verpakking zijn
                            behandeld; D) de ondeugdelijkheid geheel of
                            gedeeltelijk het gevolg is van voorschriften die de
                            overheid heeft gesteld of zal stellen ten aanzien
                            van de aard of de kwaliteit van de toegepaste
                            materialen;
                        </p>
                        <p>AANBIEDINGEN</p>
                        <p>
                            7.1 Aanbiedingen zijn vrijblijvend, tenzij anders in
                            de aanbieding is vermeld.
                        </p>
                        <p>
                            7.2 Bij aanvaarding van een vrijblijvende aanbieding
                            door koper, behoudt Kwebbels Kinderboeken zich het
                            recht voor de aanbieding binnen de termijn van 3
                            werkdagen na ontvangst van die aanvaarding te
                            herroepen of daarvan af te wijken.
                        </p>
                        <p>
                            7.3 Mondelinge toezeggingen verbinden Kwebbels
                            Kinderboeken slechts nadat deze uitdrukkelijk en
                            schriftelijk zijn bevestigd.
                        </p>
                        <p>
                            7.4 Aanbiedingen van Kwebbels Kinderboeken gelden
                            niet automatisch ook voor nabestellingen.
                        </p>
                        <p>
                            7.5 Kwebbels Kinderboeken kan niet aan haar
                            aanbieding worden gehouden indien de afnemer had
                            behoren te begrijpen dat de aanbieding, danwel een
                            onderdeel daarvan, een kennelijke vergissing of
                            verschrijving bevatte.
                        </p>
                        <p>
                            7.6 Aanvullingen, wijzigingen en/of nadere afspraken
                            zijn slechts van kracht, indien schriftelijk
                        </p>
                        <p>OVEREENKOMST</p>
                        <p>
                            8.1 Een overeenkomst tussen Kwebbels Kinderboeken en
                            een klant komt tot stand nadat een bestelling
                            opdracht door Kwebbels Kinderboeken op haalbaarheid
                            is beoordeeld.
                        </p>
                        <p>
                            8.2 Kwebbels Kinderboeken behoudt zich het recht
                            voor om zonder opgave van redenen bestellingen of
                            opdrachten niet te accepteren of uitsluitend te
                            accepteren onder de voorwaarde dat de verzending
                            geschied onder rembours of na vooruitbetaling.
                        </p>
                        <p>OVERMACHT</p>
                        <p>
                            10.1 Kwebbels Kinderboeken is niet aansprakelijk,
                            indien en voor zover haar verbintenissen niet kunnen
                            worden nagekomen ten gevolge van overmacht.
                        </p>
                        <p>
                            10.2 Onder overmacht wordt verstaan elke vreemde
                            oorzaak, alsmede elke omstandigheid, welke in
                            redelijkheid niet voor haar risico behoort te komen.
                            Vertraging bij of wanprestatie door onze
                            toeleveranciers, storingen in het Internet,
                            storingen in de elektriciteit, storingen in e-mail
                            verkeer en storingen of wijzigingen in door derden
                            geleverde technologie, vervoersmoeilijkheden,
                            werkstakingen, overheidsmaatregelen, vertragingen in
                            de aanvoer, nalatigheden van leveranciers en/of
                            fabrikanten van Kwebbels Kinderboeken alsmede van
                            hulppersonen, ziekte van personeel, gebreken in
                            hulp- of transportmiddelen gelden uitdrukkelijk als
                            overmacht.
                        </p>
                        <p>
                            10.3 Kwebbels Kinderboeken behoudt zich in het geval
                            van overmacht het recht voor haar verplichtingen op
                            te schorten en is tevens gerechtigd de overeenkomst
                            geheel of gedeeltelijk te ontbinden, danwel te
                            vorderen dat de inhoud van de overeenkomst zodanig
                            wordt gewijzigd dat uitvoering mogelijk blijft. In
                            geen geval is Kwebbels Kinderboeken gehouden enige
                            boete of schadevergoeding te betalen.
                        </p>
                        <p>
                            10.4 Indien Kwebbels Kinderboeken bij het intreden
                            van de overmacht al gedeeltelijk aan haar
                            verplichtingen heeft voldaan, of slechts
                            gedeeltelijk aan haar verplichtingen kan voldoen is
                            zij gerechtigd het reeds geleverde c.q. het
                            leverbare deel afzonderlijk te factureren en is de
                            afnemer gehouden deze factuur te voldoen als betrof
                            het een afzonderlijk contract. Dit geldt echter niet
                            als het reeds geleverde c.q. leverbare deel geen
                            zelfstandige waarde heeft.
                        </p>
                        <p>
                            10.5 Kwebbels Kinderboeken is niet aansprakelijk
                            voor schade ontstaan aan voertuigen of andere
                            objecten onstaan door verkeerd aanwenden van de
                            producten. Lees voor het gebruik de aanwijzingen op
                            de verpakking en/of raadpleeg onze website.
                        </p>
                        <p>EIGENDOMSVOORBEHOUD</p>
                        <p>
                            12.1 Eigendom van alle door Kwebbels Kinderboeken
                            aan de afnemer verkochte en geleverde zaken blijft
                            bij Kwebbels Kinderboeken zolang de afnemer de
                            vorderingen van Kwebbels Kinderboeken uit hoofde van
                            de overeenkomst of eerdere of latere gelijksoortige
                            overeenkomsten niet heeft voldaan, zolang de afnemer
                            de verrichte of nog te verrichten werkzaamheden uit
                            deze of gelijksoortige overeenkomsten nog niet heeft
                            voldaan en zolang de afnemer de vorderingen van
                            Kwebbels Kinderboeken wegens tekort schieten in de
                            nakoming van zodanige verbintenissen nog niet heeft
                            voldaan, waaronder begrepen vorderingen ter zake van
                            boeten, renten en kosten, een en ander zoals bedoeld
                            in artikel 3:92 BW.
                        </p>
                        <p>
                            12.2 De door Kwebbels Kinderboeken geleverde zaken
                            welke onder het eigendomsvoorbehoud vallen mogen
                            slechts in het kader van een normale
                            bedrijfsuitoefening worden doorverkocht en nimmer
                            als betaalmiddel worden gebruikt.
                        </p>
                        <p>
                            12.3 De afnemer is niet bevoegd de onder het
                            eigendomsvoorbehoud vallende zaken te verpanden noch
                            op enige andere wijze te bezwaren.
                        </p>
                        <p>
                            12.4 De afnemer geeft reeds nu onvoorwaardelijk en
                            onherroepelijk toestemming aan Kwebbels Kinderboeken
                            of een door Kwebbels Kinderboeken aan te stellen
                            derde om, in alle gevallen waarin Kwebbels
                            Kinderboeken haar eigendomsrechten wil uitoefenen,
                            al die plaatsen te betreden waar haar eigendommen
                            zich dan zullen bevinden en die zaken aldaar mee te
                            nemen.
                        </p>
                        <p>
                            12.5 Indien derden beslag leggen op de onder
                            eigendomsvoorbehoud geleverde zaken danwel rechten
                            daarop willen vestigen of doen gelden, is de afnemer
                            verplicht Kwebbels Kinderboeken zo snel als
                            redelijkerwijs verwacht mag worden daarvan op de
                            hoogte te stellen.
                        </p>
                        <p>
                            12.6 De afnemer verplicht zich de onder
                            eigendomsvoorbehoud geleverde zaken te verzekeren en
                            verzekerd te houden tegen brand, ontploffings- en
                            waterschade alsmede tegen diefstal en de polis van
                            deze verzekering op eerste verzoek ter inzage te
                            geven aan Kwebbels Kinderboeken.
                        </p>
                        <p>
                            12.7 Op alle overeenkomsten is het Nederlands recht
                            van toepassing.
                        </p>
                        <p>
                            12.8 Van geschillen, voortvloeiende uit een
                            overeenkomst tussen Kwebbels Kinderboeken en koper,
                            welke niet in onderling overleg kunnen worden
                            opgelost, neemt de bevoegde rechter binnen het
                            arrondissement Breda kennis, tenzij Kwebbels
                            Kinderboeken er de voorkeur aan geeft het verschil
                            aan de bevoegde rechter van de woonplaats van de
                            koper te onderwerpen, en met uitzondering van die
                            geschillen die behoren tot de competentie van de
                            kantonrechter.
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Done
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default TermsAndConditions;
