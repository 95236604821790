import Footer from "../components/Footer";
import Header from "../components/Header";

const Main = (props) => {
    return (
        <>
            <Header />
            {props.children}
            <Footer />
        </>
    );
};

export default Main;
