// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import ReducerState from "./Reducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    ReducerState: ReducerState,
});

export default rootReducer;
