import React from "react";
import headerImg from "../assets/img/header_img.png";
import { Container, Row } from "reactstrap";

const Header = () => {
    return (
        <div className="header py-3">
            <Container>
                <Row className="justify-content-center align-items-center header__row">
                    <img
                        src={headerImg}
                        alt="header"
                        className="header__img mr-3"
                    />
                    <h4 className="header__text mb-0">
                        Lisa: “Stijn vind het heerlijk om iedere avond een
                        boekje te lezen”
                    </h4>
                </Row>
            </Container>
        </div>
    );
};

export default Header;
