import ThankYou from "../components/ThankYou";
import MainView from "../views/MainView";

let routes = [
    // {
    //     path: "/",
    //     component: MainView,
    //     layout: "main",
    // },
    // {
    //     path: "/bedankt",
    //     component: ThankYou,
    //     layout: "main",
    // },
];
export default routes;
